// function App() {
//   return <div className="text-3xl font-bold underline">hiiii</div>;
// }

// export default App;
import React, { useEffect } from "react";

const ComingSoon = () => {
  // Replace this with your desired phone number
  const phoneNumber = "9167999555";

  // Add the country code
  const countryCode = "+91";

  // Combine country code and phone number
  const fullPhoneNumber = countryCode + phoneNumber;

  // Replace this with your pre-filled message
  const message = "Hello!";

  // Generate the WhatsApp link with the encoded message
  const whatsappLink = `https://wa.me/${fullPhoneNumber}?text=${encodeURIComponent(
    message
  )}`;

  console.log(whatsappLink); // Log the WhatsApp link to the console

  // const appstore = "https://apps.apple.com/in/app/kifi/id6477778946";
  // const playstore =
  //   "https://play.google.com/store/apps/details?id=com.zbeanz.kifi_app";

  useEffect(() => {
    const setThemePreference = () => {
      const currentHour = new Date().getHours();
      if (currentHour >= 19 || currentHour <= 6) {
        document.body.setAttribute("data-theme", "dark");
      } else {
        document.body.setAttribute("data-theme", "light");
      }
    };
    setThemePreference();
  }, []);
  return (
    <div className="h-screen">
      <video
        className="absolute inset-0 object-cover w-full h-full"
        src="/video/182083-867762201_tiny.mp4"
        autoPlay
        muted
        loop
      ></video>
      <>
        {/* <div className="flex flex-col justify-center items-center h-full  dark:bg-black text-gray-800 dark:text-white">
        <div className="top-0">
      <span className="w-[60px] md:hidden top-0 flex  animate-fadeIn relative z-10">
                  <img
                    src="/images/background/Kifi - Logo_Top White 2.png"
                    alt=""
                  />
                </span>
                </div>
        <div className="text-center animate-fadeIn relative z-10 bottom-10">
        
          <h1 className="md:text-6xl text-4xl text-white font-bold mb-4">
            COMING SOON...<span className="text-secondary">.</span>
          </h1>
          <p className="md:text-2xl text-xl font-bold text-tertiary mb-6">
            Catching Best Fishes
          </p>
        </div>
      </div> */}
        <div className="relative z-10 flex flex-col items-center h-full w-full">
          <div className="mt-4 md:mt-6 w-full">
            <span className="w-[60px] md:hidden top-0 left-0 flex justify-start items-start  animate-fadeIn relative z-10">
              <img
                src="/images/background/Kifi - Logo_Top White 2.png"
                alt=""
              />
            </span>
          </div>
          <div className="flex flex-grow items-center justify-center h-full w-full ">
            <div className="text-center animate-fadeIn bottom-10 relative">
              <h1 className="md:text-6xl text-4xl text-white font-bold mb-4">
                COMING SOON...<span className="text-secondary">.</span>
              </h1>
              <p className="md:text-2xl text-xl font-bold text-tertiary mb-6">
                Catching Best Fishes
              </p>
            </div>
          </div>
        </div>
        <footer className="w-full bottom-24 relative md:block  text-gray-300 py-2">
          <div className="container mx-auto px-4">
            <div className="flex flex-col md:flex-row justify-between items-center">
              <div className="text-center md:text-left mb-4 md:flex md:mb-0 hidden md:visible">
                <div className="flex items-center">
                  <span className="w-[60px] flex justify-center">
                    <img
                      src="/images/background/Kifi - Logo_Top White 2.png"
                      alt=""
                    />
                  </span>
                  <p className="mt-2"> Copyrights @helofish.</p>
                </div>
              </div>
              <div className="md:flex  space-x-4 md:mb-4 mx-auto pb-5 md:pb-0">
                <div className="flex  justify-center space-x-4">
                  <a
                    href={whatsappLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="mx-5 cursor-pointer  flex justify-center rounded-full border-gray-500  bg-white ring-2 ring-gray-600 font-mono"
                      style={{
                        height: "30px",
                        width: "30px",
                        fontSize: "170px",
                      }}
                    >
                      <span className="flex justify-center w-[30px] h-full items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_1468_67)">
                            <path
                              d="M10.5053 8.89692C10.335 8.81628 9.51963 8.41307 9.36731 8.35034C9.21499 8.28762 9.10746 8.2697 8.99098 8.43995C8.8745 8.61019 8.56089 8.97756 8.46233 9.09404C8.36376 9.21053 8.27416 9.21949 8.10392 9.09404C7.61493 8.89781 7.16333 8.61897 6.76884 8.2697C6.41173 7.93348 6.10941 7.54348 5.87282 7.11383C5.77425 6.95255 5.87282 6.86294 5.9445 6.77334C6.01618 6.68374 6.10578 6.58518 6.19538 6.48662C6.26091 6.40138 6.31512 6.30801 6.35667 6.20885C6.3789 6.16273 6.39044 6.1122 6.39044 6.061C6.39044 6.00981 6.3789 5.95928 6.35667 5.91316C6.35667 5.83252 5.98034 5.01714 5.83698 4.68561C5.69361 4.35408 5.56817 4.39888 5.46961 4.39888H5.1112C4.94046 4.40553 4.77934 4.47965 4.66318 4.60496C4.4757 4.78387 4.32719 4.99959 4.22697 5.23857C4.12675 5.47756 4.07697 5.73468 4.08077 5.9938C4.1266 6.62996 4.36051 7.23814 4.75279 7.74105C5.47263 8.81039 6.45745 9.67481 7.6111 10.2499C8.00535 10.4202 8.31 10.5187 8.55193 10.5994C8.89165 10.702 9.25073 10.7235 9.60028 10.6621C9.83243 10.615 10.0524 10.5207 10.2466 10.3851C10.4409 10.2494 10.6051 10.0753 10.7293 9.87359C10.8326 9.62444 10.8666 9.352 10.8278 9.08508C10.783 9.02236 10.6755 8.97756 10.5053 8.89692Z"
                              fill="#075E54"
                            />
                            <path
                              d="M12.1808 2.79499C11.5611 2.16946 10.8225 1.67436 10.0084 1.33888C9.19429 1.00339 8.32127 0.834332 7.44081 0.841659C6.27445 0.847763 5.1301 1.15978 4.12206 1.74654C3.11402 2.3333 2.27757 3.17427 1.69625 4.18546C1.11493 5.19664 0.809092 6.34265 0.809274 7.50903C0.809457 8.67541 1.11566 9.82132 1.69729 10.8323L0.80127 14.282L4.3316 13.386C5.30758 13.9171 6.40137 14.1944 7.51249 14.1924H7.44081C8.76506 14.201 10.0619 13.8151 11.1659 13.0837C12.2699 12.3524 13.1311 11.3088 13.6396 10.086C14.1481 8.86327 14.2809 7.51677 14.0211 6.21823C13.7613 4.91969 13.1206 3.72795 12.1808 2.79499ZM7.44081 13.0455C6.44672 13.0463 5.47111 12.7768 4.61833 12.266L4.42121 12.1495L2.32451 12.6961L2.88005 10.6531L2.7546 10.447C2.039 9.2946 1.77132 7.91958 2.00234 6.58286C2.23337 5.24614 2.94704 4.04072 4.00793 3.19532C5.06882 2.34992 6.40313 1.92336 7.7577 1.99656C9.11226 2.06976 10.3928 2.63764 11.3564 3.59245C11.873 4.10488 12.2825 4.715 12.5609 5.38725C12.8393 6.0595 12.9812 6.78045 12.9782 7.50808C12.9759 8.97597 12.3917 10.3831 11.3537 11.421C10.3158 12.459 8.9087 13.0431 7.44081 13.0455Z"
                              fill="#075E54"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1468_67">
                              <rect
                                width="14.3364"
                                height="14.3364"
                                fill="red"
                                transform="translate(0.272705 0.393616)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                    </div>
                  </a>
                  <a
                    href="https://youtube.com/@kifionline?si=uYZeJTKhjPfZYLPh"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <div
                      className="mx-5 rounded-full border-gray-500 bg-white ring-2 ring-gray-600 font-mono"
                      style={{
                        height: "30px",
                        width: "30px",
                        fontSize: "170px",
                      }}
                    >
                      <span className="flex justify-center w-[30px] h-full items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_1468_82)">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M7.28135 2.7829C7.79209 2.7829 8.31596 2.79604 8.82371 2.81754L9.42345 2.84622L9.9975 2.88027L10.5351 2.9167L11.0261 2.95493C11.559 2.99572 12.0604 3.22311 12.4422 3.59711C12.8239 3.9711 13.0616 4.46775 13.1133 4.99966L13.1372 5.25353L13.182 5.79712C13.2238 6.36042 13.2548 6.9745 13.2548 7.56169C13.2548 8.14889 13.2238 8.76296 13.182 9.32626L13.1372 9.86985C13.1294 9.95706 13.1216 10.0413 13.1133 10.1237C13.0615 10.6557 12.8238 11.1524 12.4419 11.5265C12.0601 11.9005 11.5585 12.1278 11.0255 12.1685L10.5357 12.2061L9.9981 12.2431L9.42345 12.2772L8.82371 12.3058C8.30989 12.3282 7.79566 12.3397 7.28135 12.3405C6.76705 12.3397 6.25282 12.3282 5.739 12.3058L5.13926 12.2772L4.56521 12.2431L4.02759 12.2061L3.53657 12.1685C3.00371 12.1277 2.50229 11.9003 2.12054 11.5263C1.73879 11.1523 1.50115 10.6556 1.44943 10.1237L1.42554 9.86985L1.38074 9.32626C1.33506 8.73915 1.31075 8.15057 1.30786 7.56169C1.30786 6.9745 1.33892 6.36042 1.38074 5.79712L1.42554 5.25353C1.4333 5.16632 1.44107 5.0821 1.44943 4.99966C1.50113 4.46784 1.73868 3.97127 2.12031 3.59729C2.50194 3.22331 3.00322 2.99585 3.53597 2.95493L4.0264 2.9167L4.56401 2.88027L5.13866 2.84622L5.7384 2.81754C6.25242 2.79522 6.76685 2.78367 7.28135 2.7829ZM6.08666 6.11312V9.01026C6.08666 9.28624 6.38533 9.45828 6.62427 9.32089L9.13314 7.87231C9.18773 7.84089 9.23308 7.79563 9.26461 7.7411C9.29614 7.68656 9.31274 7.62468 9.31274 7.56169C9.31274 7.4987 9.29614 7.43682 9.26461 7.38229C9.23308 7.32776 9.18773 7.2825 9.13314 7.25107L6.62427 5.8031C6.56977 5.77163 6.50794 5.75507 6.44501 5.75508C6.38207 5.75509 6.32025 5.77167 6.26576 5.80315C6.21127 5.83464 6.16603 5.87992 6.13459 5.93443C6.10315 5.98895 6.08662 6.05078 6.08666 6.11372V6.11312Z"
                              fill="red"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1468_82">
                              <rect
                                width="14.3364"
                                height="14.3364"
                                fill="red"
                                transform="translate(0.113037 0.393555)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                    </div>
                  </a>
                  <a
                    href="https://www.instagram.com/kifionline/?igsh=MWr4c3hhdXR6N2k%3D#"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <div
                      className="mx-5 rounded-full border-gray-500 bg-white  ring-2 ring-gray-600 font-mono"
                      style={{
                        height: "30px",
                        width: "30px",
                        fontSize: "170px",
                      }}
                    >
                      <span className="flex justify-center w-[30px] h-full items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                        >
                          <path
                            opacity="0.2"
                            d="M9.69213 2.40961H4.764C4.05108 2.40961 3.36736 2.69281 2.86325 3.19693C2.35913 3.70104 2.07593 4.38476 2.07593 5.09768V10.0258C2.07593 10.7387 2.35913 11.4225 2.86325 11.9266C3.36736 12.4307 4.05108 12.7139 4.764 12.7139H9.69213C10.4051 12.7139 11.0888 12.4307 11.5929 11.9266C12.097 11.4225 12.3802 10.7387 12.3802 10.0258V5.09768C12.3802 4.38476 12.097 3.70104 11.5929 3.19693C11.0888 2.69281 10.4051 2.40961 9.69213 2.40961ZM7.22807 9.8018C6.78502 9.8018 6.35193 9.67043 5.98355 9.42429C5.61518 9.17815 5.32806 8.8283 5.15852 8.41898C4.98897 8.00966 4.94461 7.55926 5.03105 7.12473C5.11748 6.6902 5.33083 6.29106 5.6441 5.97778C5.95738 5.66451 6.35652 5.45116 6.79105 5.36473C7.22558 5.27829 7.67598 5.32265 8.0853 5.4922C8.49462 5.66174 8.84447 5.94886 9.09061 6.31723C9.33675 6.68561 9.46813 7.1187 9.46813 7.56174C9.46813 8.15585 9.23212 8.72561 8.81203 9.14571C8.39193 9.5658 7.82217 9.8018 7.22807 9.8018Z"
                            fill="red"
                          />
                          <path
                            d="M7.22811 4.87366C6.69646 4.87366 6.17675 5.03131 5.7347 5.32668C5.29265 5.62205 4.94811 6.04187 4.74466 6.53305C4.5412 7.02423 4.48797 7.56471 4.59169 8.08615C4.69541 8.60758 4.95142 9.08655 5.32736 9.46248C5.70329 9.83842 6.18226 10.0944 6.7037 10.1982C7.22513 10.3019 7.76561 10.2486 8.25679 10.0452C8.74797 9.84173 9.16779 9.49719 9.46316 9.05514C9.75853 8.61309 9.91618 8.09338 9.91618 7.56173C9.91618 6.84881 9.63298 6.16509 9.12887 5.66098C8.62476 5.15686 7.94103 4.87366 7.22811 4.87366ZM7.22811 9.35378C6.87368 9.35378 6.5272 9.24868 6.2325 9.05176C5.9378 8.85485 5.70811 8.57497 5.57248 8.24752C5.43684 7.92006 5.40135 7.55974 5.4705 7.21212C5.53964 6.86449 5.71032 6.54518 5.96094 6.29456C6.21157 6.04394 6.53088 5.87326 6.8785 5.80411C7.22612 5.73497 7.58645 5.77046 7.9139 5.90609C8.24135 6.04173 8.52123 6.27142 8.71815 6.56612C8.91506 6.86082 9.02016 7.2073 9.02016 7.56173C9.01868 8.03656 8.8294 8.49151 8.49365 8.82727C8.1579 9.16302 7.70294 9.3523 7.22811 9.35378Z"
                            fill="red"
                          />
                          <path
                            d="M9.69215 1.96155H4.76401C3.93227 1.96155 3.1346 2.29196 2.54647 2.88009C1.95834 3.46822 1.62793 4.26589 1.62793 5.09763V10.0258C1.62793 10.8575 1.95834 11.6552 2.54647 12.2433C3.1346 12.8314 3.93227 13.1618 4.76401 13.1618H9.69215C10.5239 13.1618 11.3216 12.8314 11.9097 12.2433C12.4978 11.6552 12.8282 10.8575 12.8282 10.0258V5.09763C12.8282 4.26589 12.4978 3.46822 11.9097 2.88009C11.3216 2.29196 10.5239 1.96155 9.69215 1.96155ZM11.9322 10.0258C11.9322 10.3199 11.8743 10.6112 11.7617 10.883C11.6491 11.1548 11.4841 11.4017 11.2761 11.6097C11.0681 11.8177 10.8212 11.9827 10.5494 12.0953C10.2776 12.2079 9.98631 12.2658 9.69215 12.2658H4.76401C4.46984 12.2658 4.17856 12.2079 3.90678 12.0953C3.635 11.9827 3.38806 11.8177 3.18005 11.6097C2.97204 11.4017 2.80704 11.1548 2.69447 10.883C2.58189 10.6112 2.52395 10.3199 2.52395 10.0258V5.09763C2.52395 4.50353 2.75996 3.93376 3.18005 3.51367C3.60014 3.09358 4.16991 2.85757 4.76401 2.85757H9.69215C9.98631 2.85757 10.2776 2.91551 10.5494 3.02809C10.8212 3.14066 11.0681 3.30566 11.2761 3.51367C11.4841 3.72168 11.6491 3.96862 11.7617 4.2404C11.8743 4.51217 11.9322 4.80346 11.9322 5.09763V10.0258Z"
                            fill="red"
                          />
                          <path
                            d="M10.14 5.32164C10.5112 5.32164 10.8121 5.02076 10.8121 4.64962C10.8121 4.27847 10.5112 3.9776 10.14 3.9776C9.76889 3.9776 9.46802 4.27847 9.46802 4.64962C9.46802 5.02076 9.76889 5.32164 10.14 5.32164Z"
                            fill="red"
                          />
                        </svg>
                      </span>
                    </div>
                  </a>
                  <a
                    href="https://www.facebook.com/kifionline/"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <div
                      className="mx-5 rounded-full border-gray-500 bg-white ring-2 ring-gray-600 font-mono"
                      style={{
                        height: "30px",
                        width: "30px",
                        fontSize: "170px",
                      }}
                    >
                      <span className="flex justify-center w-[30px] h-full items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                        >
                          <path
                            d="M8.58232 8.45777H10.0757L10.673 6.06838H8.58232V4.87368C8.58232 4.25841 8.58232 3.67898 9.77702 3.67898H10.673V1.67189C10.4783 1.6462 9.74297 1.58826 8.96642 1.58826C7.34462 1.58826 6.19293 2.57806 6.19293 4.3958V6.06838H4.40088V8.45777H6.19293V13.5352H8.58232V8.45777Z"
                            fill="#004680"
                          />
                        </svg>
                      </span>
                    </div>
                  </a>
                </div>
              </div>
              <footer className=" bottom-2 relative">
                <span className="md:hidden flex justify-center w-full ">
                  <p className=""> Copyrights @helofish.</p>
                </span>
              </footer>
              <div className="md:flex hidden md:visible space-x-4 ">
                <a
                  href="/Privacy"
                  className="hover:text-white transition duration-300"
                >
                  Privacy Policy
                </a>
                <a
                  href="/termsandconditions"
                  className="hover:text-white transition duration-300"
                >
                  Terms of Service
                </a>
                <a
                  href="/Contact"
                  className="hover:text-white transition duration-300"
                >
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </footer>
      </>
    </div>
  );
};

export default ComingSoon;
